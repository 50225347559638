<template>
  <div>
    <MediaWidgetInit
      key="image-2"
      :folder="`${baseFolder}/images`"
      cld-key="image-2"
      @selected-value="onSelectedValue2"
    />
    <MediaWidgetInit
      key="image-3"
      :folder="`${baseFolder}/images`"
      cld-key="image-3"
      @selected-value="onSelectedValue3"
    />
    <MediaWidgetInit
      key="image-1"
      :folder="`${baseFolder}/images`"
      cld-key="image-1"
      @selected-value="onSelectedValue"
    />
    <cld-image
      v-for="item in assets"
      :public-id="item.public_id"
      :key="item.public_id"
      :cloud-name="env.VUE_APP_CLOUDINARY_CLOUD_NAME"
    >
      <cld-transformation 
        width="250" 
        height="250" 
        gravity="faces" 
        crop="fill" />
    </cld-image>
    <cld-image
      v-for="item in assets2"
      :public-id="item.public_id"
      :key="item.public_id"
      :cloud-name="env.VUE_APP_CLOUDINARY_CLOUD_NAME"
    >
      <cld-transformation 
        width="250" 
        height="250" 
        gravity="faces" 
        crop="fill" />
    </cld-image>
    <cld-image
      v-for="item in assets3"
      :public-id="item.public_id"
      :key="item.public_id"
      :cloud-name="env.VUE_APP_CLOUDINARY_CLOUD_NAME"
    >
      <cld-transformation 
        width="250" 
        height="250" 
        gravity="faces" 
        crop="fill" />
    </cld-image>
  </div>
</template>

<script>
import MediaWidgetInit from '@/components/MediaWidgetInit'
import { CldImage, CldTransformation } from 'cloudinary-vue'
import env from '@/env'

export default {
  name: 'Index',
  components: {
    MediaWidgetInit,
    CldImage,
    CldTransformation,
  },
  setup() {
    return {
      env
    }
  },
  data() {
    return {
      assets: [],
      assets2: [],
      assets3: [],
    }
  },
  computed: {
    baseFolder() {
      return env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER
    },
  },
  methods: {
    onSelectedValue(assets) {
      this.assets = assets
      console.log(assets, 'eeeee')
    },
    onSelectedValue2(assets) {
      this.assets2 = assets
      console.log(assets, '<< 2')
    },
    onSelectedValue3(assets) {
      this.assets3 = assets
      console.log(assets, '<< 3')
    },
  },
}
</script>

<style scoped></style>
